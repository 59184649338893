<template>
  <v-app>
    <Navbar /> 
    <v-main class="main">
      <Roulette/>
    </v-main>    
  </v-app>
</template>

<script>
import Navbar from "@/components/shared/Navbar"; // Import the Navbar component
import Roulette from "@/views/raffles/Roulette"; // Import the Roulette component
export default { // Export the component
  components: {
    Navbar,
    Roulette,    
  },
};
</script>