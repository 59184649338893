import Service from './Services.js';

export default {
    getNumbers(config){
        return Service.get('getRandomNumberCustomer',config);
    },
    saveWinner(config,data){
        return Service.post('saveWinner',config,data);
    },
    getWinners(config){
        return Service.get('getWinners',config);
    }
}