<template>
  <div class="lottery d-flex justify-center">
    <div class="d-flex lottie-confetti">
      <lottie-player
        class="lottie-player"
        src="https://assets2.lottiefiles.com/packages/lf20_0y2x6xwo.json"
        background="transparent"
        speed="0.5"
        loop
        autoplay
      ></lottie-player>
      <lottie-player
        class="lottie-player"
        src="https://assets2.lottiefiles.com/packages/lf20_0y2x6xwo.json"
        background="transparent"
        speed="0.5"
        loop
        autoplay
      ></lottie-player>
    </div>
    
    <div class="d-flex justify-center align-center logo-coljuegos">
      <v-img src="@/assets/img/lottery/coljuegos.png" contain max-width="200" />
    </div>

    <div class="border-circle d-flex justify-center align-center">
      <div class="leds">
        <div class="led" v-for="n in 10" :key="n.index" :class="['led'+n]"></div>
      </div>
      <div class="container-circle d-flex justify-center align-center">
        <div class="logo d-flex flex-column justify-center align-center">
          <v-img src="@/assets/img/navbar.png" contain max-width="250" />
          <v-img src="@/assets/img/lottery/letter.png" contain max-width="400" class="mt-3"/>
        </div>
        <div class="machine d-flex justify-center align-center">
          <div class="machine-main">
            <div class="main-inner numbers black">
              <div class="stage d-flex justify-space-between align-center">
                <ol class="wheel">
                  <li class="number border-left" id="num0">0</li>
                </ol>
                <ol class="wheel">
                  <li class="number" id="num1">0</li>
                </ol>
                <ol class="wheel">
                  <li class="number" id="num2">0</li>
                </ol>
                <ol class="wheel">
                  <li class="number border-right" id="num3">0</li>
                </ol>
              </div>
            </div>
          </div>
          <div class="machine-main">
            <div class="main-inner series black">
              <div class="stage d-flex justify-center align-center">
                <ol class="wheel wheel-serie">
                  <li class="number border-left" id="num4">0</li>
                </ol>
                <ol class="wheel wheel-serie">
                  <li class="number" id="num5">0</li>
                </ol>
                <ol class="wheel wheel-serie">
                  <li class="number border-right" id="num6">0</li>
                </ol>
              </div>
            </div>
            <input
              id="active"
              type="checkbox"
              style="display: none"
              @click="!isRunner ? runLottery() : ''"
            />
            <div class="lever">
              <div class="pull">
                <label for="active">
                  <div class="ball"></div>
                </label>
                <div class="stem"></div>
              </div>
              <div class="cog1"></div>
              <div class="cog2"></div>
            </div>
          </div>
        </div>
        <div class="winners-led">
          <div class="winners-content">
            <div class="winners text-uppercase">
              <div class="winners1 d-flex flex-column justify-space-between">
                <div>
                  <h2 class="text-center text-white">Nombre</h2>
                </div>
                <div class="win text-h5 d-inline-block">                  
                  <div class="winner-name text-truncate" :class="winners[2] ? 'one' : ''">3. {{ winners[2] ? ( winners[2].customer_name ? winners[2].customer_name : winners[2].partner_name)  : " " }}</div>
                </div>
                <div class="win text-h5 d-inline-block ">
                  
                  <div class="winner-name text-truncate">2. {{ winners[1] ? ( winners[1].customer_name ? winners[1].customer_name : winners[1].partner_name ) : " " }}</div> 
                </div>
                <div class="text-h5 d-inline-block">
                  <div class="winner-name text-truncate">1. {{ winners[0] ? ( winners[0].customer_name ? winners[0].customer_name : winners[0].partner_name ) : " " }}</div> 
                </div>
              </div>
              <div class="winners2 d-flex flex-column justify-space-between">
                <div>
                  <h2 class="text-center text-white">Número</h2>
                </div>
                <div class="win text-h5 text-center">                 
                  <div class="winner-name" :class="winners[2] ? 'one' : 'white--text'" >{{ winners[2] ? " "+ winners[2].number_played.substr(0, 4)+" "+  winners[2].number_played.substr(4): "-" }}</div>
                </div>
                <div class="win text-h5 text-center">
                  <div class="winner-name" :class="!winners[1] ? 'white--text':''">{{ winners[1] ? " "+ winners[1].number_played.substr(0, 4)+" "+  winners[1].number_played.substr(4) : "-" }}</div>
                </div>
                <div class="text-h5 text-center">
                  <div class="winner-name" :class="!winners[0] ? 'white--text':''" >{{ winners[0] ? " "+ winners[0].number_played.substr(0, 4)+" "+  winners[0].number_played.substr(4) : "-" }}</div>
                </div>
              </div>
            </div>
          </div> 
          <div class="logo-motorcycle">
            <v-img src="@/assets/img/lottery/motorcycle.png" alt="" width="400px" />
          </div>
        </div>
      </div>
    </div>
    <v-overlay :value="overlay">
      <div class="winner">
        <p>{{ nameWinner }}</p>
      </div>
      <lottie-player
        src="https://assets1.lottiefiles.com/private_files/lf30_kvdn44jg.json"
        background="transparent"
        speed="1"
        style="width: 500; height: 500"
        loop
        autoplay
      ></lottie-player>
    </v-overlay>
    <v-snackbar v-model="alert" :color="color" timeout="4000" top>{{
      text
    }}</v-snackbar>
    <v-overlay v-if="loader" class="loader">
      <div class="d-flex justify-center align-center">
        <v-img src="@/assets/img/navbar.png" contain max-width="450" />
        <v-progress-linear
          indeterminate
          color="#c5cd27"
          class="progress-loader"
        ></v-progress-linear>
      </div>
    </v-overlay>
  </div>
</template>

<script>
// script
import LotteryService from "@/Services/lotteryServices.js"; //importamos el servicio de loteria
export default {
  //exportamos el componente

  components: {}, //componentes
  data() {
    //data es una funcion que se ejecuta cuando se carga el componente y se puede usar para inicializar variables
    return {
      numberLottery: [],
      overlay: false,
      numbersLottery: [],
      drawNumber: 1,
      nameWinner: "",
      winners: [],
      showWinners: false,
      color: "",
      text: "",
      alert: false,
      user_id: "",
      token: "",
      isActive: false,
      loader: true,
      isRunner: false,
    };
  },
  mounted() {
    //mounted es una funcion que se ejecuta cuando se carga el componente
    this.user_id = localStorage.getItem("user_id");
    this.token = localStorage.getItem("token");
    this.getWinners();
    setTimeout(() => {
      this.loader = false;
    }, 3000);
  },
  methods: {
    //metodos son funciones que se pueden usar para realizar acciones

    getNumbers() {
      //funcion para obtener los numeros de la loteria
      let getX , getY , x, y;
      let numberArray = [];

      getX = function () {
        x = Math.ceil(Math.random() * 9) - 1;

        return x;
      };

      getY = function () {
        y = Math.ceil(Math.random() * 9) - 1;
        return y;
      };

      for (let i = 1; i <= 7; i++) {
        getX();
        if (numberArray.indexOf(x) !== -1) {
          getX();
        }
        numberArray.push(x);
      }

      getY();
      numberArray.push(y);

      for (let j = 0; j < numberArray.length; j++) {
        if (j <= 6) {
          document.getElementById("num" + j).innerHTML = numberArray[j];
        }
      }
    },

    replaceLottery() {
      //funcion para reemplazar los numeros de la loteria

      for (let i = 0; i < this.numberLottery.length; i++) {
        document.getElementById("num" + i).innerHTML = this.numberLottery[i];
      }

      if (this.drawNumber === 4) {
        this.showWinners = true;
      }
    },
    assignNumber() {
      //funcion para asignar los numeros de la loteria
      
      if (this.numbersLottery.customer_name) {
        this.nameWinner = this.numbersLottery.customer_name.toUpperCase();
        this.numbersLottery.customer_name =
        this.numbersLottery.customer_name.toUpperCase();
      } else {
        this.nameWinner = this.numbersLottery.partner_name.toUpperCase();
        this.numbersLottery.partner_name =
        this.numbersLottery.partner_name.toUpperCase();
      }  
      let number =
        this.numbersLottery.number_played.substr(3, 4) +
        "" +
        this.numbersLottery.number_played.substr(0, 3);
      this.numberLottery = number.split("");

      if (this.winners.length == 0) {
        this.numbersLottery.position = 1;
      } else if (this.winners.length == 1) {
        this.numbersLottery.position = 2;
      } else if (this.winners.length == 2) {
        this.numbersLottery.position = 3;
      }

      let winner = {
        lottery_id: this.numbersLottery.lottery_id,
        user_id: this.user_id,
        customer_id: this.numbersLottery.customer_id,
        partner_id: this.numbersLottery.partner_id,
        number_played:"" +
          this.numbersLottery.number_played.substr(3, 4) +
          "" +
          this.numbersLottery.number_played.substr(0, 3),
        position: this.numbersLottery.position,
      };
      this.saveWinner(winner);
    },

    runLottery() {
      //funcion para ejecutar la loteria    

      if (this.winners.length < 3 && this.isActive) {
        this.isRunner = true;
        this.assignNumber();

        let refreshIntervalId = setInterval(() => {
          this.getNumbers();
        }, 150);

        setTimeout(() => {
          clearInterval(refreshIntervalId);
          this.replaceLottery();
          this.getCustomersNumbers();          
          this.overlay = true;

          setTimeout(() => {
            this.overlay = false;
            document.getElementById("active").checked = false;            
            this.isRunner = false;
          }, 3000);
        }, 5000);
      } else {
        this.messageError("Sorteo ya  finalizado");
        document.getElementById("active").disabled = true;
      }
    },

    async getCustomersNumbers() {
      //funcion para obtener los numeros de los clientes
      let config = {
        headers: {
          Authorization: this.token,
        },
        params: { user_id: this.user_id},
      };

      await LotteryService.getNumbers(config)
        .then((response) => {
          if (response.data.status) {
            this.numbersLottery = response.data.data;
            this.isActive = true;
          } else if (response.data.message == "unauthenticated") {
            this.messageError("Usuario no autenticado");
            localStorage.removeItem("token");
            localStorage.removeItem("user_id");
            setTimeout(() => {
              this.$router.push("/login");
            }, 2000);
          } else if (response.data.message != "Sorry, you have already won 3 times" && !response.data.status) {
            this.isActive = false;
            this.messageError(response.data.message);
          }
        })
        .catch(function (error) {
          this.messageError(error);
          console.error(error);
        });
    },
    saveWinner(winner) {
      //funcion para guardar los ganadores
      let config = {
        headers: {
          Authorization: this.token,
        },
        params: { user_id: this.user_id },
      };

      LotteryService.saveWinner(winner, config)
        .then((response) => {
          if (response.data.status) {
            setTimeout(() => {
              this.winners.push(response.data.data);
            }, 5000);
          } else if (response.data.message == "unauthenticated") {
            this.messageError("Usuario no autenticado");
            localStorage.removeItem("token");
            localStorage.removeItem("user_id");
            setTimeout(() => {
              this.$router.push("/login");
            }, 2000);
          }
        })
        .catch(function (error) {
          this.messageError(error);
          console.error(error);
        });
    },
    getWinners() {
      let config = {
        headers: {
          Authorization: this.token,
        },
        params: { user_id: this.user_id },
      };
      LotteryService.getWinners(config)
        .then((response) => {
          if (response.data.status) {
            this.winners = response.data.winners;
            if (this.winners.length < 3) {
              this.getCustomersNumbers();
            }
          } else if (response.data.message == "unauthenticated") {
            this.messageError("Usuario no autenticado");
            localStorage.removeItem("token");
            localStorage.removeItem("user_id");
            setTimeout(() => {
              this.$router.push("/login");
            }, 2000);
          }else{
            this.isActive = false;
            this.messageError(response.data.message);
          }
        })
        .catch(function (error) {
          console.error(error);
        });
    },
    messageError(error) {
      this.color = "error";
      this.text = error;
      this.alert = true;
    },
  },
};
</script>

<style>
  html {
    overflow: hidden;
  }
  .winner {
    font-size: 50px;
    font-weight: bold;
    color: #fff;
    text-align: center;
    position: absolute;
    top: 50%;
    left: 40%;
  }
  .lottery {
    background: url("../../assets/img/lottery/background-raffles.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
    height: 100%;
    padding-top: 50px;
  }

  .lottie-confetti {
    position: absolute;
  }
  .logo-coljuegos {
    position: absolute;
    bottom: 30px;
    right: 30px;
  }
  .lottie-player {
    width: 100%;
    height: calc(100vh - 60px);
  }

  .border-circle{
    height: 700px;
    width: 700px;
    background: linear-gradient(0deg, rgba(3,11,92,1) 0%, rgba(9,9,121,1) 70%, rgba(8,126,235,1) 100%);
    border-radius: 100%;
    box-shadow: inset 0 0 30px rgba(3,11,92,1);
    z-index: 1;
    zoom: 0.9;
  }

  .container-circle {
    height: 600px;
    width: 600px;
    border: 20px solid black;
    background: linear-gradient(0deg, rgb(0 4 7) 40%, rgb(0 48 85) 80%, rgb(0 93 161) 100%);
    border-radius: 100%;
    position: relative;
  }

  .logo {
    top: 20px;
    position: absolute;
  }

  .winners-led {
    width: 880px;
    height: 270px;
    border-radius: 15px;
    background: linear-gradient(90deg, rgba(0,0,0,1) 10%, rgba(0,77,135,1) 40%, rgba(0,133,233,1) 50%, rgba(0,77,135,1) 60%, rgba(0,0,0,1) 90%);
    border: 1px solid #e0d767;
    position: absolute;
    bottom: -225px;
    filter: drop-shadow(0px 5px 3px #373737);
  }

  .winners-content {
    width: 820px;
    height: 220px;
    border: 1px solid #e0d767;
    box-shadow: 0 0 10px 5px rgb(224 215 103 / 25%); 
    position: absolute;
    bottom: 25px;
    left: 30px;
  }

  .winners {
    width: 630px;
    height: 170px;
    position: absolute;
    top: 15px;
    margin: 10px 30px;
  }


  .winners1 {
    width: 60%;
    height: 100%;
    border-right: 2px solid #e0d767;
    position: absolute;
    bottom: 0;
  }

  .win {
    border-bottom: 2px solid #e0d767;
  }

  .winners2 {
    width: 40%;
    height: 100%;
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .winner-name {
    font-weight: 600;
    color: white;
    font-family: 'Rajdhani', sans-serif !important;
    width: 100%;
  }

  .winner-name.one{
    font-size: 35px;
    font-weight: bold;
    text-shadow: 2px 2px 2px #424242;
    color: #c5cd27;
    animation: zoom 1s ease infinite;
  }

  @keyframes zoom{
    0% {
      transform: scale(0.95, 0.95);
    }
    50% {
      transform: scale(1, 1);
    }
    100% {
      transform: scale(0.95, 0.95);
    }
  }

  .logo-motorcycle {
    position: absolute;
    top: -50px;
    right: -230px;
  }

  .machine {
    position: absolute;
    gap: 10px;
    top: 235px;
  }

  .machine-main {
    border: 1px solid #8b5a0d;
    padding: 10px;
    background: #d78f21;
    box-shadow: inset 0 0 10px #dddddd;
    border-radius: 10px;
    position: relative;
  }

  .main-inner {
    border-radius: 4px;
    border: 1px solid #4f4f4f;
  }

  .main-inner.numbers {
    width: 600px;
    height: 250px;
  }

  .main-inner.series {
    width: 300px;
    height: 220px;
  }
 
  .main-inner.series .number,
  .main-inner.numbers .number {
    font-family: "Lora", serif;
    color: #a6851b;
    text-shadow: #bbb 0 0 1px, #ffff5d 0 2px 2px, #ad9032 0 0px 4px, rgb(0 0 0 / 80%) 0 8px 15px;
    font-size: 180px;
  }

  .main-inner.series .number {
    font-size: 130px !important;
  }

  .stage {
    height: 100%;
  }

  .wheel {
    list-style: none;
    width: 25%;
    height: 100%;
    padding: 0 !important;
  }

  .wheel.wheel-serie {
    width: 33.33% !important;
  }

  .wheel .numbers {
    font-family: "Lora", serif;
    width: 112px;
  }

  .wheel .number.border-left {
    border-top-left-radius: 20%;
    border-bottom-left-radius: 20%;
  }

  .wheel .number.border-right {
    border-top-right-radius: 20%;
    border-bottom-right-radius: 20%;
  }

  .wheel li {
    height: 100%;
    border-radius: 12px;
    font-size: 50px;
    color: black;
    border: 1px solid black;
    background-color: white;
    box-shadow: 2px 2px 2px rgb(0 0 0 / 40%);
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(
      to bottom,
      rgba(40, 40, 40, 1) 0%,
      rgb(218, 218, 218) 39%,
      rgb(190, 190, 190) 57%,
      rgba(40, 40, 40, 1) 100%
    );
  }

  .lever {
    position: absolute;
    right: -62px;
    top: -60px;
  }

  .ball {
    width: 42px;
    height: 42px;
    border-radius: 50%;
    background-image: radial-gradient(circle at 30% 40%, rgb(90, 90, 90), #000);
    position: relative;
    z-index: 0;
    top: 2px;
    margin-left: 20px;
    cursor: pointer;
  }

  .stem {
    width: 15px;
    height: 100px;
    margin-left: 35px;
  }

  .stem,
  .cogs b {
    background: linear-gradient(
      to right,
      #8b5a0d 0%,
      #d78f21 39%,
      #d78f21 57%,
      #8b5a0d 100%
    );
  }

  .cog1,
  .cog2 {
    float: left;
    width: 30px;
    height: 100px;
    border: 1px solid #8b5a0d;
    background: linear-gradient(
      to bottom,
      #8b5a0d 0%,
      #d78f21 39%,
      #d78f21 57%,
      #8b5a0d 100%
    );
    margin: -15px 0 0;
  }

  .cog2 {
    width: 30px;
    height: 65px;
    margin: 0;
  }

  .lever .pull {
    transform: rotate(0deg) translateX(0px);
  }

  input#active:checked ~ .lever .pull {
    -webkit-animation: pull 2s linear;
    -moz-animation: pull 2s linear;
    animation: pull 1s linear;
  }

  @keyframes pull {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(20deg) translateX(20px);
    }
  }

  @media (max-width: 1200px) {
    .border-circle{
      zoom: 0.8;
    }
  }
  .loader {
    background-color: #120740 !important;
  }
  .progress-loader {
    height: 4px;
    position: absolute;
    bottom: 0;
  }

  .text-white{
    color: white;
  }

  .led {
    font-size: 60%;
    margin: 0 50px;
    position: relative;
    display: inline-block;
    width: 1em;
    height: 1em;
    border-radius: 50%;
    -webkit-animation: blink 0.8s infinite;
    -moz-animation: blink 0.8s infinite;
    -o-animation: blink 0.8s infinite;
    animation: blink 0.8s infinite;
    background: #eddd72;
    color: #f5e473;
    position: absolute;
  }

  @keyframes blink {
    0% {
      box-shadow: inset 0.25em 0.25em 0.25em rgba(255, 255, 255, 0.25);
    }
    50% {
      box-shadow: 0 0 1em 0.5em;
    }
    100% {
      box-shadow: inset 0.25em 0.25em 0.25em rgba(255, 255, 255, 0.25);
    }
  }

  .leds {
    position: absolute;
    top: 62px;
  }

  .led1{
    top: 275px;
    left: -372px;
  }
  .led2{
    top: 190px;
    left: -340px;
  }
  .led3{
    top: 108px;
    left: -280px;
  }
  .led4{
    top: 50px;
    left: -200px;
  }
  .led5{
    top: 20px;
    left: -100px;
  }
  .led6{
    top: 20px;
  }
  .led7{
    top: 56px;
    right: -215px;
  }
  .led8{
    top: 120px;
    right: -295px;
  }
  .led9{
    top: 200px;
    right: -350px;
  }
  .led10{
    top: 290px;
    right: -375px;
  }
</style>